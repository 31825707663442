.navbar {
  height: 70px;
  left: 250px;
  right: 5px;
  position: absolute;
  z-index: 890;
  background-color: transparent;
  &.active {
    background-color: color(primary);
    @include shadow(to_opacity(primary, .2));
  }
}

.navbar-bg {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 115px;
  background-color: color(primary);
  z-index: -1;
}

.navbar {
  align-items: center;
  .navbar-brand {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
  }
  .active {
    .nav-link {
      color: #fff;
      font-weight: 700;
    }
  }
  .navbar-text {
    color: #fff;
  }
  .nav-link {
    color: darken(#fff, 5%);
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    &.nav-link-lg {
      div {
        margin-top: 3px;
      }
      i {
        margin-left: 0 !important;
        font-size: 18px;
        line-height: 32px;
      }
    }
    &.nav-link-user {
      color: #fff;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: 600;
      img {
        width: 30px;
      }
    }
    &.nav-link-img {
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 50%;
      overflow: hidden;
      .flag-icon {
        @include shadow;
        border-radius: 50%;
        line-height: 18px;
        height: 22px;
        width: 22px;
        background-size: cover;
      }
    }
  }
}

.remove-caret:after {
  display: none;
}

.navbar .nav-link {
  &:hover {
    color: #fff;
  }
  &.disabled {
    color: #fff;
    opacity: .6;
  }
}

.nav-collapse {
  display: flex;
}

@include media-breakpoint-down(xs) {
  body.search-show .navbar .form-inline .search-element {
    display: block;
  }
  .navbar {
    .form-inline {
      .search-element {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        z-index: 892;
        display: none;
        .form-control {
          float: left;
          border-radius: 3px 0 0 3px;
          width: calc(100% - 43px) !important;
        }
        .btn {
          margin-top: 1px;
          border-radius: 0 3px 3px 0;
        }
      }
      .search-result {
        width: 100%;
      }
      .search-backdrop {
        display: none;
      }
    }
    .nav-link.nav-link-lg div {
      display: none;
    }
  }
}

@include media-breakpoint-only(sm) {
  .navbar .form-inline .search-element {
    display: block;
  }
}
@include media-breakpoint-only(md) {
  .collapse {
    position: relative;
    .navbar-nav {
      position: absolute;
    }
  }
}

@media (max-width: 1024px) {
  .nav-collapse {
    position: relative;
    .navbar-nav {
      @include shadow(0 0 30px);
      position: absolute;
      top: 40px;
      left: 0;
      width: 200px;
      display: none;
      &.show {
        display: block;
      }
      .nav-item {
        &:first-child {
          border-radius: 3px 3px 0 0;
        }
        &:last-child {
          border-radius: 0 0 3px 3px;
        }
        .nav-link {
          background-color: #fff;
          color: color(font);
          &:hover {
            background-color: color_lighten(light, 7.6%);
            color: color(primary);
          }
        }
        &:focus,
        &.active {
          > a {
            background-color: color(primary);
            color: #fff;
          }
        }
      }
    }
  }
  .navbar {
    left: 5px;
    right: 0;
    .dropdown-menu {
      position: absolute;
    }
    .navbar-nav {
      flex-direction: row;
    }
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
}
