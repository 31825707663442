//Btn top
.card-header.top{
  min-height: auto!important;
  .btn-top{
    position: absolute;
    top: 15px;
    right: 16px;
    z-index: 1;
  }
}


.box-image{
  display: flex;
  padding: 10px;

  background: #f4f6f9;
  align-items: center;
  justify-content: center;
  height: 300px;
  overflow: hidden;
  img.img-fluid{
    max-width: 100%;
    max-height: 100%;

  }
}
//Style tab
.nav-pills .nav-item
{
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  &:hover{
    background-color: transparent;
  }
  .nav-link{
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: transparent;
    &.active {
      box-shadow: 0px 2px 5px 0px rgba(166,166,166,0.7);
      color: #fff;
      background-color: #003664;
    }

  }
}


.main-wrapper{
    height: 100vh;
}
.action-delete {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

.action-delete {
  background-color: #f9fafe !important;
  border: 1px solid #f9fafe !important;
}
.text-red {
  color: #fa2121 !important;
}

td > a.btn-default {
  background-color: #f9fafe;
  border: 1px solid #f9fafe;
  padding: 0 0.4rem !important;
  font-size: 10px !important;
}
.modal-header {
  padding-bottom: 0 !important;
  padding: 15px !important;
}
#loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  display: none;
  background-color: rgba(255, 255, 255, 0.51);
}
.loader {
  position: relative;
  margin: 0 auto;
  width: 60px;
  top: 50%;
  margin-top: -100px;
}

.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}
@-webkit-keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

.line-scale-pulse-out > div {
  background-color: #495057;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85); }
.line-scale-pulse-out > div:nth-child(2), .line-scale-pulse-out > div:nth-child(4) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important; }
.line-scale-pulse-out > div:nth-child(1), .line-scale-pulse-out > div:nth-child(5) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important; }

@-webkit-keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1); }
  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3); }
  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1); }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    border: 1px solid !important;
    border-color: #dc3545 !important;
}

.select2{
  width : 100% !important;
}
.wizard-content-active {
  display: block !important;
}

.wizard-content {
  display: none;
}

.hidden{
  display : none !important;
}

.btn-active {
  display : inline-block;
}

.note {
  color : red;
  font-weight: bold;
}

.prev-img {
  width: 100px;
  height: 100px !important;
  border: 2px dashed #ddd;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  color: #ecf0f1;
}

.accordion-header button.btn-outline-primary{
  color : #ffffff;
}

.accordion-header button.btn-outline-primary:hover{
  background-color: transparent !important;
  color : #ffffff !important;
}


.accordion .accordion-body{
  background-color: #f4f6f9;
}

.custom-switch{
  padding-left: 0 !important;
}

.custom-switch-description {
  margin-right: 0.5rem;
}

.accordion .accordion-body .accordion-subbody {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #78828a;
}

.td {
  padding : 0 10px;
}

.card-header-botton{
  margin-left: 10px;
}

.card-header .card-header-form + .card-header-botton  {
  margin-left: auto;
}

.btn-back{
  font-size: 25px !important;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 15px;
}
.w-12 {
    width: 12% !important;
}

.file-hidden {
    position: absolute;
    width: 200px;
    height: 40px;
    overflow: hidden;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.btn-sm.btn-warning, .btn-group-sm > .btn.btn-warning {
    background-color: #FFA100;
    border: #FFA100;
    padding: 0 0.4rem !important;
    font-size: 10px !important;
}

.pointer {
    cursor: pointer !important;
}

//--Card Bootstrap--//
.card{
    .card-header{
        border-bottom-color: #f9f9f9;
        line-height: 30px;
        -ms-grid-row-align: center;
        align-self: center;
        width: 100%;
        min-height: 50px;
        padding: 0 25px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

/*-----Collapse-----*/
[role=button] {
    .icon{
        i{
            transform: rotate(0deg)
        }
    }
    &[aria-expanded=true]{
        .icon{
            i{
                transform: rotate(90deg)
            }
        }
    }
}
